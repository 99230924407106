.header-container-fluid {
  /* @apply pr-0 pl-5 lg:container-fluid; */
  @apply flex items-center justify-between;
}

a.header-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  color: #000;
  font-family: "Satoshi";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 18.2px */
  letter-spacing: 0.65px;
  text-transform: uppercase;
  text-align: center;
  text-wrap: nowrap;
}
