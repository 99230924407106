/* .modalbase {
  @apply fixed flex w-screen h-screen justify-center z-50 top-0 left-0 overflow-hidden bg-black bg-opacity-50 p-8;
}
.modal {
  @apply flex bg-[#545454] bg-opacity-25 backdrop-filter backdrop-blur-2xl rounded-[20px];
}
 */

.modalbase {
  @apply fixed flex w-screen h-[100dvh] items-center justify-center top-0 left-0 overflow-hidden bg-black bg-opacity-50 p-8;
  z-index: 9998;
  /* height: 100%; */
  /* height: -webkit-fill-available; */
  @media only screen and (max-width: 500px) {
    padding: 20px;
  }
}
.modal {
  @apply flex relative bg-white border border-bg rounded-xl;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
}

.modal-content {
  @apply flex-1;
}
